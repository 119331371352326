<ngx-loading [show]="loading"></ngx-loading>
<div class="wrapper">
  <div id="disclaimer">
    <div class="row">
      <div class="col-12 page-title">
        <h1>Welcome to the {{schema}} data call</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>
    <div *ngIf="!loading && data.length === 0">
      <div class="row mb-1">
        <alert class="col-4" type="info">
          <span id="alert:noFiling">There are no filings assigned to this login ID.</span>
        </alert>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div id="disclaimerDiv">
          <p>Follow these steps to submit a filing:</p>
          <ol>
            <li>Click the filing link to enter the form.</li>
            <li>Enter filing data in the form.</li>
            <li>Click the 'Save & Validate' Button to validate your entered data.</li>
            <li>Once all error validations are resolved, go to the Summary page and click
              a final 'Save & Validate' to confirm no errors appear
              and then click 'Submit.'

            </li>
          </ol>
          Your assigned filing will display below.
        </div>
        <br />
      </div>
      <div *ngIf="data.length > 0" class="col-12">
        <div>
          <table id="assignments-table" class="table table-bordered shortened">
            <thead>
              <tr>
                <th *ngFor="let key of getKeys(data)" id={{key}}
                    class="font-weight-bold text-capitalize">{{key}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data">
                <td>
                  <a class="text-primary assignment-values" (click)="reDirect(item.assignmentKey)">
                    {{ item.assignmentKey.assignmentValue }}
                  </a>
                </td>
                <td class="status-values">{{ item.status }}</td>
                <td *ngIf="onlineDataEntryService.isAllowAttachments">
                  <ul>
                    <li *ngFor="let attachment of item.attachments">
                      <a class="text-primary assignment-values" (click)="downloadAttachment(attachment)">
                        {{ attachment.originalFileName }} - {{attachment.uploadDate}}
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>