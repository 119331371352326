<div class="wrapper">

    <div [formGroup]="sectionForm">
      <div [formGroupName]="section.key" class="well well-lg" [id]="section.key">
  
        <div class="row pb-1" *ngIf="isSectionVisible">
          <div class="col-6">
            <h4>
              <span>
                {{section.name}}
              </span>
            </h4>
          </div>
        </div>
  
        <div *ngIf="isSectionVisible">
          <div *ngFor="let model of updatedModel;">
            <div class="row pl-3" *ngIf="model.label === '' else question">
              <!-- Heading -->
              <ng-container >
                <div class="col">
                  <h5>{{model.heading}}</h5>
                </div>
              </ng-container>
              <hr/>
            </div>
            <ng-template #question>
                <!-- Questions -->
                <div [formGroupName]="model.label" class="col well well-lg indent-questions" [id]="model.label">
                  <div class="form-group row pl-5">
                    <div class="col-4">
                      <b>{{getQuestionNumber(model.label)}}</b>&nbsp;&nbsp;&nbsp;&nbsp;<a (click)="goToSelectedQuestion(model.text, model.key, section.key)"><u>{{model.text}}</u></a>
                    </div>
                  </div>
                </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- More Sections -->
  <ng-container>
    <app-set-dependencies-select-questions *ngFor="let child of section.sections; let i = index"
                      [section]="child" 
                      [parentId]="getSectionId()"
                      [filteredQuestions] = "filteredQuestions"
                      [isSectionVisible] = "isSectionVisible">
      </app-set-dependencies-select-questions>
  </ng-container>
  