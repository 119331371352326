import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { TooltipPosition } from '@angular/material';
import { User } from '../../model/user';
import { UserService } from '../../service/user.service';
import { isNullOrUndefined } from 'util';
import {FilingBlank} from '../model/filingblank';
import {FormQuestion} from '../model/form-question';
import {FormSection} from '../model/form-section';
import { OnlineDataEntryService } from '../services/online-data-entry.service';

@Component({
  selector: 'app-form-summary',
  templateUrl: './form-summary.component.html',
  styleUrls: ['./form-summary.component.css']
})
export class FormSummaryComponent implements OnInit, OnDestroy {
  @Input() formSection: FilingBlank;
  @Output() closeSummary: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter(); 
  @Output() download: EventEmitter<any> = new EventEmitter();
  questions: any[] = [];
  count = {
    current: 1
  };
  tableHeaders: any[];
  allSections: any;
  formAttachments: any[];
  tooltipPosition: TooltipPosition = 'above';
  user: User;
  isDisabled: boolean;

  constructor(
    public onlineDataEntryService: OnlineDataEntryService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.onlineDataEntryService.isSummaryPage = true;
    this.tableHeaders = ["File Name", "Delete"];
    this.allSections = this.onlineDataEntryService.getSummaryPageSections();
    this.user = this.userService.getStoredUser();
    this.isDisabled = this.userService.canViewFileAdminLink(this.user);
    this.onlineDataEntryService.postSaveAttachments.subscribe((value: boolean) => {
      if(value) {
        this.handleAttachments();
      }
    })
  }

  handleAttachments() {
    this.formAttachments = !isNullOrUndefined(this.onlineDataEntryService.allSectionsForSummary) ? this.onlineDataEntryService.allSectionsForSummary.attachments : null;
  }

  deleteAttachment(attachment) {
    this.onlineDataEntryService.formSummary.next(true);
    this.delete.emit(attachment);
  }

  ngOnDestroy() {
    this.onlineDataEntryService.isSummaryPage = false;
    this.onlineDataEntryService.formSummary.next(false);
    this.onlineDataEntryService.uploadAttachments.next(false);
  }

  // Looping all the questions
  public mapSectionQuestions(section: FormSection[]) {
    section.forEach((ele: FormSection) => {
      ele.questions.forEach((question: FormQuestion) => {
        question.inputs.forEach(input => {
          this.questions.push({
            question: question.text,
            answer: input.value ? input.value : 'N/A'
          });
        });
      });
      if (ele.sections) {
        this.mapSectionQuestions(ele.sections);
      }
    });
    return (this.questions);
  }

  // Action on Previous button
  previous() {
    this.closeSummary.emit();
  }

  downloadAttachment(attachment) {
    this.onlineDataEntryService.formSummary.next(true);
    this.download.emit(attachment);
  }
}
