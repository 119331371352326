import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OnlineSubmissionsStatus } from '../../../online-data-entry/model/online-submissions-status';
import { MessageService } from '../../../messages/service/message.service';
import { OnlineDataEntryService } from '../../../online-data-entry/services/online-data-entry.service';
import { UserService } from '../../../service/user.service';
import { FormSection } from '../../model/form-section';
import { DependenciesService } from '../services/dependencies.service';
import { User } from '../../../model/user';
import { faCaretDown, faCaretRight, faCaretUp, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormBuilderService } from '../../services/form-builder.service';
import { isNullOrUndefined } from 'util';
import { cloneDeep } from 'lodash';
import * as _ from 'lodash';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommonConfirmationModalComponent } from '../../../common-confirmation-modal/common-confirmation-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertMessage } from '../../../messages/model/alert-message';

@Component({
  selector: 'app-set-dependencies-establish-rule',
  templateUrl: './set-dependencies-establish-rule.component.html',
  styleUrls: ['./set-dependencies-establish-rule.component.css']
})

export class SetDependenciesEstablishRuleComponent implements OnInit, OnDestroy {
  @Input() formSection: FormSection;
  @Input() section: FormSection;
  @Input() status: string;
  @Input() parentId: string;
  @Input() count: number;
  @Input() disabled = false;
  @Input() previous: string;
  @Input() next: string;
  @Input() parentPath: string;
  @Input() summary: boolean;
  @Input() questionNo: any;
  @Output() goToSection = new EventEmitter<string>();
  @Input() filteredQuestions: any;
  @Input() isSectionVisible: boolean;

  sectionForm: FormGroup;
  path: any;
  inputLabels: any[] = [];
  sectionHeaders: any = {};
  updatedModel: any;
  user: User;
  isCollapsed = false;
  faPlus = faPlus;
  faMinus = faMinus;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faCaretRight = faCaretRight;
  isCheckBoxVisible: boolean = true;
  isCheckBoxDisabled: boolean = true;
  selectedSingleValue: string;
  valuesToBeUpdated: any = [{ schemaPropertyNameKey: "", value: "" }];
  selectedExistingRuleKeys: any[];
  faTimes = faTimes;
  modalSubscriptions: Subscription[] = [];
  bsModalRef: BsModalRef;

  constructor(
    private dependenciesService: DependenciesService,
    private onlineDataEntryService: OnlineDataEntryService,
    private messageService: MessageService,
    private userService: UserService,
    private formBuilderService: FormBuilderService,
    private cdr: ChangeDetectorRef,
    public modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.getInputLabels(this.section);
    this.sectionForm = this.dependenciesService.generateForms(this.section, this.isFormSectionEnabled(), this.summary);
    this.getOrderOfSections(this.section);
    this.dependenciesService.clonedArray = cloneDeep(this.formBuilderService.filingBlank.sections); // Deep clone the sections
    this.dependenciesService.clonedValues = JSON.parse(JSON.stringify(this.valuesToBeUpdated));
    this.checkSingleOrMultipleInputs(this.dependenciesService.clonedArray);
    this.dependenciesService.isUserOnDependencyPage = true;
  }

  checkSingleOrMultipleInputs(sections) {
    sections.forEach(section => {
      section.questions.forEach(question => {
        if (question.text === this.dependenciesService.selectedQuestion && question.key === this.dependenciesService.selectedQuestionKey) {
          let count = 1;
          question.inputs.forEach(input => {
            if (input.controlType === 'dropdown') {
              this.dependenciesService.inputCount = count;
              count++;
            }
          });
        }
      });
      if (section.sections.length > 0) {
        this.checkSingleOrMultipleInputs(section.sections);
      }
    });
  }

  ngOnDestroy() {
    this.dependenciesService.isCheckBoxDisabled = true;
    this.dependenciesService.selectedExistingRuleKeys = [];
    this.dependenciesService.selectedSingleValue = '';
    this.dependenciesService.selectedSingleKey = '';
    this.dependenciesService.inputCount = 0;
    this.dependenciesService.clonedArray = null;
    this.dependenciesService.isUserOnDependencyPage = false;
  }

  getInputLabels(section: FormSection) {
    this.inputLabels = [];
    if (section.questions.length > 0) {
      let question = section.questions[0];
      question.inputs.forEach((input, index) => {
        this.sectionHeaders[input.label] = index;
        this.inputLabels.push({ label: input.label, key: input.key });
      });
    }
  }

  getOrderOfSections(section: FormSection) {
    this.updatedModel = this.onlineDataEntryService.setFormData(section);
    this.updatedModel = this.dependenciesService.addDependentHeaders(this.updatedModel);
    const indexToRemove = this.updatedModel.findIndex(item => item.key === this.dependenciesService.selectedQuestionKey && item.type === "question");
    if (indexToRemove !== -1) {
      this.dependenciesService.isQuestionSelected = true;
      for (let i = indexToRemove - 1; i >= 0; i--) {
        if (this.updatedModel[i].type === "question") {
          this.updatedModel.splice(i, 1);
        }
      }
    }
    this.updatedModel = this.dependenciesService.removeNonDependentOrders(this.updatedModel);
    if (!this.dependenciesService.isQuestionSelected) {
      delete this.updatedModel;
    }
    this.isSectionVisible = (!isNullOrUndefined(this.updatedModel)) ? this.updatedModel.some((x) => Number(x.label) > 0) : false;
  }

  isFormSectionEnabled(): boolean {
    return this.status === OnlineSubmissionsStatus.PROCESSING || this.userService.canViewFileAdminLink(this.user);
  }

  getSectionId(): string {
    let sectionId = '';
    if (this.parentId) {
      sectionId = `${this.parentId}-${this.section.key}`;
    } else {
      sectionId = this.section.key;
    }
    return sectionId;
  }

  public getQuestionNumbers() {
    this.questionNo.current = this.questionNo.current + 1;
    return this.questionNo;
  }

  getSectionStyles(): string[] {
    let headerNumber = this.section.sectionNumber + 3;
    let marginLeftNumber =
      (this.section.sectionNumber === 1
        ? this.section.sectionNumber - 1
        : this.section.sectionNumber) + 2;
    let styles: string[] = [];
    styles.push(`align-bottom`);
    return styles;
  }

  getInputStyle(input: any): string[] {
    let styles: string[] = ['form-control', 'form-control-sm'];
    return styles;
  }

  formatSectionName(sectionName: string): string {
    return this.formatSectionsName(sectionName);
  }

  formatSectionsName(sectionName: string): string {
    let sections = sectionName.split('.');
    return sections.join(' > ');
  }

  getQuestionNumber(key: string): number {
    let keyArray = key.split("-");
    return parseInt(keyArray[keyArray.length - 1], 10);
  }

  getBackgroundQuestion(index, questionKey): string {
    if (index === this.dependenciesService.selectedQuestion && questionKey === this.dependenciesService.selectedQuestionKey) {
      this.isCheckBoxVisible = false;
      return 'backgroundText'
    }
  }

  getOptionsVisible(index, key): boolean {
    if (index === this.dependenciesService.selectedQuestion && key === this.dependenciesService.selectedQuestionKey) {
      return true;
    } else {
      return false;
    }
  }

  toggleCollapse(event) {
    this.isCollapsed = !this.isCollapsed
  }

  changeSelectedQuestionValue(changeEvent, input) {
    this.messageService.clear();
    let keyExists = false;
    let indexToDelete = -1;
    const clonedValues = cloneDeep(this.dependenciesService.clonedValues);
    const clonedArray = cloneDeep(this.formBuilderService.filingBlank.sections);

    clonedValues.forEach((rule, index) => {
      if (rule.schemaPropertyNameKey === "") {
        indexToDelete = index;
      } else if (rule.schemaPropertyNameKey === input.key) {
        rule.value = changeEvent.target.value;
        keyExists = true;
      }
    });

    if (indexToDelete !== -1) {
      clonedValues.splice(indexToDelete, 1);
    }

    if (!keyExists) {
      clonedValues.push({ schemaPropertyNameKey: input.key, value: changeEvent.target.value });
    }
    this.dependenciesService.selectedSingleValue = changeEvent.target.value;
    this.dependenciesService.selectedSingleKey = input.key;
    if (clonedValues.length === this.dependenciesService.inputCount) {
      // this.dependenciesService.selectedSectionKeys = [];
      this.checkForExistingRuleInputsAndUpdate(clonedArray, clonedValues);
      this.dependenciesService.isCheckBoxDisabled = false;
    }
    this.dependenciesService.clonedArray = clonedArray;
    this.dependenciesService.clonedValues = clonedValues;
    this.cdr.detectChanges();
  }

  checkForExistingRuleInputsAndUpdate(masterFilingBlank, ruleInputs) {
    masterFilingBlank.forEach(section => {
      section.questions.forEach(question => {
        if (question.text === this.dependenciesService.selectedQuestion && question.key === this.dependenciesService.selectedQuestionKey) {
          let keyExists = false;
          question.rules.forEach(rule => {
            if (_.isEqual(rule.ruleInputs, ruleInputs)) {
              this.checkForExistingRuleKeysAndSetRules(rule);
              keyExists = true;
            }
          });
          if (!keyExists) {
            question.rules.push({ ruleInputs: ruleInputs, ruleKeys: [] });
            this.checkForExistingRuleKeysAndSetRules({ ruleInputs: ruleInputs, ruleKeys: [] });
          }
        }
        if (section.sections.length > 0) {
          this.checkForExistingRuleInputsAndUpdate(section.sections, ruleInputs);
        }
      });
    });
  }

  checkForExistingRuleKeysAndSetRules(rule) {
    this.dependenciesService.selectedExistingRuleKeys = [];
    this.dependenciesService.selectedExistingRuleKeys = rule.ruleKeys;
    this.formBuilderService.selectedExistingRuleKeys = cloneDeep(this.dependenciesService.selectedExistingRuleKeys);
    // this.formBuilderService.selectedExistingRuleKeys = rule.ruleKeys;
    this.cdr.detectChanges();
  }

  isSectionOrQuestionOrInputChecked(model): boolean {
    return this.dependenciesService.selectedExistingRuleKeys.length < 1 ? false : this.dependenciesService.selectedExistingRuleKeys.some(item => item.key === model.key);
  }

  isCheckBoxDisabledForDependency(section): boolean {
    return this.dependenciesService.isCheckBoxDisabled || this.dependenciesService.selectedExistingRuleKeys.some(obj => obj.key === section.key);
  }

  isSectionCheckBoxDisabledForDependency(section): boolean {
    return this.dependenciesService.isCheckBoxDisabled;
  }

  checkSection(checkBoxEvent, section) {
    const clonedArray = cloneDeep(this.dependenciesService.clonedArray);

    // if checked -> select every key
    // if unchecked -> remove every key
    let keysForSectionOrRemoval: any[] = [];

    keysForSectionOrRemoval.push({ key: section.key });
    section.questions.forEach(question => {
      keysForSectionOrRemoval.push({ key: question.key });
    });
    section.questions[0].inputs.forEach(input => {
      keysForSectionOrRemoval.push({ key: input.key });
    });

    if (checkBoxEvent.target.checked) {
      let keysSetForAddition: Set<string> = new Set(this.dependenciesService.selectedExistingRuleKeys.map(obj => obj.key));
      keysForSectionOrRemoval.forEach(obj => {
        if (!keysSetForAddition.has(obj.key)) {
          this.dependenciesService.selectedExistingRuleKeys.push(obj);
        }
      });
    } else {
      let keysSetForRemoval: Set<string> = new Set(keysForSectionOrRemoval.map(obj => obj.key));
      this.dependenciesService.selectedExistingRuleKeys = this.dependenciesService.selectedExistingRuleKeys.filter(obj => !keysSetForRemoval.has(obj.key));
    }

    this.setRuleKeysForRuleInputs(clonedArray, section.key, checkBoxEvent.target.checked, 'section');
    this.dependenciesService.clonedArray = clonedArray;
  }

  changeInput(checkBoxEvent, label, section, allInputs) {
    const clonedArray = cloneDeep(this.dependenciesService.clonedArray);
    this.setRuleKeysForRuleInputs(clonedArray, label.key, checkBoxEvent.target.checked, 'input');
    let isEveryInputSelected = this.checkIfAllInputsAreChecked(allInputs);
    if(isEveryInputSelected) {
      this.checkSection(checkBoxEvent, section);
    }
    this.dependenciesService.clonedArray = clonedArray;
  }

  changeQuestion(checkBoxEvent, model, allQuestions, section) {
    const clonedArray = cloneDeep(this.dependenciesService.clonedArray);
    this.setRuleKeysForRuleInputs(clonedArray, model.key, checkBoxEvent.target.checked, 'question');
    let isEveryInputSelected = this.checkIfAllInputsAreChecked(allQuestions);
    if(isEveryInputSelected) {
      this.checkSection(checkBoxEvent, section);
    }
    this.dependenciesService.clonedArray = clonedArray;
  }

  checkIfAllInputsAreChecked(allInputs) {
    const selectedSet: Set<string> = new Set(this.dependenciesService.selectedExistingRuleKeys.map(item => item.key));
    let allSelected = true;
    allInputs.forEach(input => {
        if (input.key !== "" && !selectedSet.has(input.key)) {
            allSelected = false;
        }
    });

    return allSelected;
  }

  setRuleKeysForRuleInputs(masterFilingBlank, ruleKey, isChecked, type) {
    const selectedQuestion = this.dependenciesService.selectedQuestion;
    const selectedQuestionKey = this.dependenciesService.selectedQuestionKey;
    const clonedValues = this.dependenciesService.clonedValues;

    const processRule = (rule, type) => {
        if (!_.isEqual(rule.ruleInputs, clonedValues)) return;

        if (isChecked) {
            rule.ruleKeys = type === 'section' ? this.dependenciesService.selectedExistingRuleKeys : [...rule.ruleKeys, { key: ruleKey }];
        } else {
            rule.ruleKeys = type === 'section' ? this.dependenciesService.selectedExistingRuleKeys : rule.ruleKeys.filter(item => item.key !== ruleKey);
        }
        this.dependenciesService.selectedExistingRuleKeys = rule.ruleKeys;
    };

    const processQuestion = (question) => {
        if (question.text !== selectedQuestion || question.key !== selectedQuestionKey) return;

        question.rules.forEach(rule => {
            processRule(rule, type);
        });
    };

    const processSection = (section) => {
        section.questions.forEach(question => {
            processQuestion(question);
        });
        if (section.sections.length > 0) {
            this.setRuleKeysForRuleInputs(section.sections, ruleKey, isChecked, type);
        }
    };

    masterFilingBlank.forEach(section => {
        processSection(section);
    });
}

  isDeleteDisabled() {
    return this.dependenciesService.isCheckBoxDisabled || this.formBuilderService.selectedExistingRuleKeys.length === 0 || !this.isSaveDisabled();
  }
  
  isSaveDisabled(): boolean {
    let isFormChanged: boolean = false;
    if(_.isEqual(this.formBuilderService.selectedExistingRuleKeys, this.dependenciesService.selectedExistingRuleKeys) === true) {
      isFormChanged = true;
    } else {
      if(this.dependenciesService.selectedExistingRuleKeys.length === 0) {
        isFormChanged = true;
      }
    }
    return isFormChanged;
  }

  deleteRule(question) {
    const initialState = {
      message: 'Do you wish to proceed with the delete?',
      title: 'Form Builder'
    };
    const bsModalRef = this.modalService.show(CommonConfirmationModalComponent, { initialState, class: 'modal-lg' });
    bsModalRef.content.isYesButtonClicked.subscribe((data: any) => {
      if (data) {
        this.dependenciesService.isSelectQuestionsVisible.next(false);
        this.dependenciesService.isDependencyQuestionClicked.next(true);
        this.confirmDelete(question);
      }
      bsModalRef.hide();
    });
  }

  confirmDelete(question) {
    this.messageService.clear();
    this.dependenciesService.deleteRules(this.user.currentSchema, question.key).subscribe(res => {
      if(res.status === 201 || 200) {
        this.formBuilderService.initiateFilingBlank.next(true);
        let messageText: string = 'Deletion was successful.';
        this.messageService.add(new AlertMessage(messageText, 'success'));
      } else {
        this.messageService.clear();
        let messageText: string = 'Error while deletion, please try again.';
        this.messageService.add(new AlertMessage(messageText, 'danger'));
      }
     });
  }
  
}

