import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {BreadcrumbService} from '../../service/breadcrumb.service';
import {ValidationSchema} from '../model/validation-schema';
import {
  AvailablePropertyTypes,
  FileValidationMaintenanceService
} from '../services/file-validation-maintenance.service';
import {Property} from '../model/property';
import {Router} from '@angular/router';
import {Breadcrumb} from '../../model/breadcrumb';
import * as _ from 'lodash';
import {MessageService} from '../../messages/service/message.service';
import {AlertMessage} from '../../messages/model/alert-message';
import {XlsLocation} from '../model/xls-location';
import {DatacallSettingService} from '../../roleconfig/datacall-setting/services/datacall-setting.service';
import { AddNextPreviousProperty } from '../model/add-next-previous-property.enum';
import { UserService } from '../../service/user.service';
import { ANSI } from '../../../assets/reservedWords/ANSI';

@Component({
  selector: 'app-schema-property-view',
  templateUrl: './schema-property-view.component.html',
  styleUrls: ['./schema-property-view.component.css']
})
export class SchemaPropertyViewComponent implements OnInit, OnDestroy {
  title: string;
  messageText: string;
  error = false;
  validationSchema: ValidationSchema;
  property: Property;
  originalProperty: Property;
  propertyTypes: string[];
  availableValidations: string[];
  breadcrumb = new Breadcrumb('Schema Property Maintenance', '/schemaPropertyMaintenance');
  breadcrumbs: Breadcrumb[];
  isRequired: boolean;
  isNewProperty = false;
  modalRef: BsModalRef;
  breakChanges: any;
  validationToDelete: string;
  subscriptions: Array<Subscription> = [];
  hasSaved: boolean;
  listOfPropertyAssignmentKeys: any[] = [];
  assignmentKeySelected: boolean;
  assignedToUser: any;
  isAssignedToUserObject: any;
  showFileType: any;
  nameAlreadyExists: boolean;
  nameHasSpaces: boolean = false;
  newTab: { [x: number]: any; };
  loading = false;
  infoMessage: string;
  isOnlineDataEntryForm = false;
  isNextDisplayed: boolean = true;
  isPreviousDisplayed: boolean = true;
  isNameFieldDisabled: boolean = false;
  formBuilderInfoMessage: string;
  isReservedWords: boolean = false;

  constructor(
    private schemaMaintenanceService: FileValidationMaintenanceService,
    private router: Router,
    public breadcrumbService: BreadcrumbService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private datacallSettingService: DatacallSettingService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.assignedToUser = this.schemaMaintenanceService.getIsAssignedToUser();
    this.getProperty();
    this.setPropertyAssignmentKey();
    this.isOnlineDataEntryForm = this.datacallSettingService.getOnlineDataEntrySetting();
    this.infoMessage = 'Changing field name may require expression updates and may affect previously loaded data.';
    this.formBuilderInfoMessage = 'This field is used in Form Builder and must first be removed before changing the Name.';
    this.isRequired = this.property.required;
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.availableValidations = this.schemaMaintenanceService.getListOfAvailableValidationsForValidationProperty(this.property);
    this.title = `${this.validationSchema.type.toUpperCase()} - ${this.property.name}`;
    this.propertyTypes = AvailablePropertyTypes;
    this.hasSaved = false;
    this.showFileType = this.schemaMaintenanceService.showFileType;
    this.isNameFieldDisabled = this.schemaMaintenanceService.getUsedSchemaProperties().includes(this.property.name);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getPropertiesKeys(): string[] {
    return _.keys(this.validationSchema.properties);
  }

  setPropertyAssignmentKey(): void {
    const propertyKeys: Array<string> = this.getPropertiesKeys();
    let listOfProperties: any[] = [];
    propertyKeys.forEach(x => {
      listOfProperties.push(this.validationSchema.properties[x]);
    });
    listOfProperties.forEach(x => {
      this.listOfPropertyAssignmentKeys.push(x.isAssignmentsKey);
    });
    this.disableAssignmentsKey();
  }

  registerAssignmentKeyChange(event) {
    this.property.isAssignmentsKey = event.target.checked;
    if (this.isOnlineDataEntryForm) {
      this.property.isSubmissionKey = this.property.isAssignmentsKey;
    }
  }

  disableAssignmentsKey(): boolean {
    if (this.listOfPropertyAssignmentKeys.includes(true) && this.originalProperty.isAssignmentsKey) {
      if (this.assignedToUser.assignedToUser === true) {
        this.isAssignedToUserObject = true; /* { message: 'Users have been assigned', value: true } */
        return true;
      } else {
        return false;
      }
    } else {
      if (!this.listOfPropertyAssignmentKeys.includes(true)) {
        return false;
      } else {
        return true;
      }
    }
  }

  getProperty(): void {
    this.validationSchema = this.schemaMaintenanceService.getSelectedValidationSchema();
    if (this.schemaMaintenanceService.getNewProperty()) {
      this.isNewProperty = true;
      this.property = _.cloneDeep(this.schemaMaintenanceService.getNewProperty());
      this.originalProperty = _.cloneDeep(this.schemaMaintenanceService.getNewProperty());
    } else {
      this.isNewProperty = false;
      this.property = _.cloneDeep(this.validationSchema.properties[this.schemaMaintenanceService.getSelectedPropertyName()]);
      this.originalProperty = _.cloneDeep(this.validationSchema.properties[this.schemaMaintenanceService.getSelectedPropertyName()]);
    }
    if (this.validationSchema.fileType === 'XLSX' && this.property.xlsLocation === undefined) {
      this.property.xlsLocation = new XlsLocation();
      this.originalProperty.xlsLocation = new XlsLocation();
    }
    let propertiesLength = this.schemaMaintenanceService.getLengthOfRows();
    let orderNumber = this.property.orderNumber;
    this.showNextAndPrevious(orderNumber, propertiesLength);
  }

  changeName(property) {
    let newKey = this.property.name;
    let oldKey = this.originalProperty.name;
    let reservedWords = ANSI;
    let isSameSchemaName: boolean = this.schemaMaintenanceService.isPropertyKeyDuplicate(newKey, this.validationSchema);
    if (isSameSchemaName) {
      if (newKey === oldKey) {
        this.nameAlreadyExists = false;
      } else {
        this.nameAlreadyExists = true;
      }
    } else if (reservedWords.includes(this.property.name)) {
      this.isReservedWords = true;
    }
    else {
      this.isReservedWords = false;
      this.nameAlreadyExists = false;
    }
  }

  saveProperty(): void {
    this.loading = true;
    this.messageService.clear();
    this.hasSaved = true;
    this.setProperty();
    const sub = this.schemaMaintenanceService.saveValidationSchema(this.validationSchema)
      .subscribe(
        res => {
          this.error = false;
          this.messageText = 'The property has been updated';
          this.messageService.add(new AlertMessage(this.messageText, 'success'));
          this.title = `${this.validationSchema.type.toUpperCase()} - ${this.property.name}`;
          this.schemaMaintenanceService.setSelectedValidationSchema(this.validationSchema);
          this.schemaMaintenanceService.setSelectedPropertyName(this.property.name);
          this.schemaMaintenanceService.setNewProperty(this.property);
          const keys: Array<string> = this.getPropertiesKeys();
          this.schemaMaintenanceService.setLengthOfRows(keys.length);
          this.getProperty();
          this.availableValidations = this.schemaMaintenanceService.getListOfAvailableValidationsForValidationProperty(this.property);
          this.loading = false;
        }
      );
    this.subscriptions.push(sub);
  }

  setProperty() {
    this.validationSchema.properties[this.property.name] = this.property;
    if (this.property.name !== this.originalProperty.name) {
      delete this.validationSchema.properties[this.originalProperty.name];
    }
  }

  setType(type: string): void {
    this.property.type = type;
    if (type === 'string') {
      delete this.property['max'];
      delete this.property['min'];
    }
    this.availableValidations = this.schemaMaintenanceService.getListOfAvailableValidationsForValidationProperty(this.property);
  }

  save(template: TemplateRef<any>): void {
    this.getBrakeChanges();
    if (this.breakChanges.changed.length > 0) {
      this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
    } else {
      this.saveProperty();
    }
  }

  cancel(): void {
    const lastPage: Breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
    this.breadcrumbService.removeBreadcrumb(lastPage);
    this.router.navigate([lastPage.link]);
  }

  cancelSave(): void {
    this.breakChanges.changed.forEach(change => this.property[change] = this.originalProperty[change]);
    this.error = false;
    this.messageText = `The following fields have been reverted: ${this.breakChanges.fields.join(', ')}`;
    this.modalRef.hide();
  }

  confirmSave(): void {
    this.saveProperty();
    this.modalRef.hide();
  }

  private getBrakeChanges(): void {
    const breakChanges = {};
    const fields: string[] = [];
    const changed: string[] = [];
    if (this.property.required !== this.originalProperty.required) {
      changed.push('required');
      fields.push('required');
    }
    if (this.property.isDocumentKey !== this.originalProperty.isDocumentKey) {
      changed.push('isDocumentKey');
      fields.push('record key');
    }
    if (this.property.isSubmissionKey !== this.originalProperty.isSubmissionKey) {
      changed.push('isSubmissionKey');
      fields.push('submission key');
    }
    breakChanges['fields'] = fields;
    breakChanges['changed'] = changed;
    this.breakChanges = breakChanges;
  }

  getPropertyKeys(): string[] {
    return Object.keys(this.property);
  }

  shouldDisplay(key: string): boolean {
    const notDisplayed = ['name', 'required', 'type', 'isSubmissionKey', 'isDocumentKey', 'xlsLocation', 'isAssignmentsKey', 'orderNumber'];
    return _.indexOf(notDisplayed, key) === -1;
  }

  delete(property: string, template: TemplateRef<any>): void {
    this.validationToDelete = property;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  deleteValidationFromProperty() {
    delete this.property[this.validationToDelete];
    this.availableValidations = this.schemaMaintenanceService.getListOfAvailableValidationsForValidationProperty(this.property);
    this.modalRef.hide();
  }

  addValidationToProperty(availableValidation: string) {
    _.set(this.property, availableValidation, null);
    this.availableValidations = this.schemaMaintenanceService.getListOfAvailableValidationsForValidationProperty(this.property);
  }

  isPropertyUnchanged(): boolean {
    const unchanged = _.isEqual(this.property, this.originalProperty);
    return unchanged;
  }

  getPropertyDisplayValue(property: string): string {
    switch (property) {
      case 'enum':
        return 'List Of Valid Values';
      case 'max':
        return 'Max Value';
      case 'min':
        return 'Min Value';
      case 'maxLength':
        return 'Max Length';
      case 'minLength':
        return 'Min Length';
      case 'anyOf':
        return 'Any Of';
      case 'pattern':
        return 'Pattern';
      default:
        return property;
    }
  }

  addNewProperty() {
    let propertiesLength = this.schemaMaintenanceService.getLengthOfRows();
    let newPropertyName: string = `new_property_${propertiesLength + 1}`
      const propertyToAdd: Property = {
        name: newPropertyName,
        type: 'string',
        required: false,
        isSubmissionKey: false,
        isDocumentKey: false,
        isAssignmentsKey: false,
        description: '',
        orderNumber: propertiesLength + 1,
        xlsLocation: this.validationSchema.fileType === 'XLSX' ? new XlsLocation() : null
      };
      this.go_to_add_next_previous(AddNextPreviousProperty.ADD, propertyToAdd);

  }

  next() {
    let property = this.schemaMaintenanceService.getSelectedPropertyName();
    let propertiesLength = this.schemaMaintenanceService.getLengthOfRows();
    let orderNumber = this.validationSchema.properties[property].orderNumber;
    let nextProperty = this.findNextOrPreviousProperty(orderNumber+1);
    if(orderNumber < propertiesLength) {
      this.go_to_add_next_previous(AddNextPreviousProperty.NEXT, nextProperty);
    }
  }

  previous() {
    let property = this.schemaMaintenanceService.getSelectedPropertyName();
    let orderNumber = this.validationSchema.properties[property].orderNumber;
    let previousProperty = this.findNextOrPreviousProperty(orderNumber-1);
    if(orderNumber > 1) {
      this.go_to_add_next_previous(AddNextPreviousProperty.PREVIOUS, previousProperty);
    }
  }

  findNextOrPreviousProperty(order) {
    const key = Object.keys(this.validationSchema.properties).find(property => this.validationSchema.properties[property].orderNumber === order)
    return this.validationSchema.properties[key]
  }

  showNextAndPrevious(orderNumber: any, propertiesLength: number) {
    if(orderNumber === 1) {
      this.isPreviousDisplayed = false;
      this.isNextDisplayed = true;
    } else if(orderNumber === propertiesLength || orderNumber >= propertiesLength) {
      this.isNextDisplayed = false;
      this.isPreviousDisplayed = true;
    } else {
      this.isNextDisplayed = true;
      this.isPreviousDisplayed = true;
    }
  }

  go_to_add_next_previous(functionality: string, property: Property) {
    if (functionality === AddNextPreviousProperty.ADD) {
      this.schemaMaintenanceService.setNewProperty(property);
      this.schemaMaintenanceService.setSelectedPropertyName(property.name);
    } else {
      this.schemaMaintenanceService.setNewProperty(null);
      this.schemaMaintenanceService.setSelectedPropertyName(property.name);
    }
    this.getProperty();
    this.isNameFieldDisabled = this.schemaMaintenanceService.getUsedSchemaProperties().includes(this.property.name);
    this.setPropertyAssignmentKey();
    this.availableValidations = this.schemaMaintenanceService.getListOfAvailableValidationsForValidationProperty(this.property);
  }

  isAddValidationDisabled(): boolean {
    if (this.availableValidations.length > 0) {
      return false;
    } else {
      return true;
    }
  }
}
