import {OnInit, Component, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OnlineDataEntryService} from '../services/online-data-entry.service';
import {OnlineSubmissions} from '../model/online-submissions';
import {MessageService} from '../../messages/service/message.service';
import {AlertMessage} from '../../messages/model/alert-message';
import {OnlineSubmissionsStatus} from '../model/online-submissions-status';
import { User } from '../../model/user';
import { UserService } from '../../service/user.service';
import { DatePipe } from '@angular/common';
import { DatacallSettingEnum } from '../../roleconfig/datacall-setting/model/datacall-setting.enum';
import { DatacallSettingService } from '../../roleconfig/datacall-setting/services/datacall-setting.service';

@Component({
  selector: 'app-user-filing',
  templateUrl: './user-filing.component.html',
  styleUrls: ['./user-filing.component.css']
})
export class UserFilingComponent implements OnInit, OnDestroy {
  loading = false;
  schema: string;
  data: OnlineSubmissions[] = [];
  user: User;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private onlineDataEntryService: OnlineDataEntryService,
    private messageService: MessageService,
    private userService: UserService,
    private datePipe: DatePipe,
    private datacallSettingService: DatacallSettingService
  ) {
  }

  ngOnInit(): void {
    this.user = this.userService.getStoredUser();
    this.schema = this.route.snapshot.paramMap.get('schema');
    this.getAllOnlineSubmissions();
  }

  ngOnDestroy() {
    this.messageService.clear();
  }

  public getKeys(data) {
    let keys = [];

    if (this.data.length !== 0) {
      // if there is at least one submission returned, then get the value of the assignment field property, which can be different for every data call
      const assignmentField = data[0].assignmentKey.assignmentField;
      keys.push(assignmentField);
      // all submissions will have a status
      keys.push('Filing Status');
      if(this.onlineDataEntryService.isAllowAttachments) { keys.push('Attachments'); };
    }
    return keys;
  }

  reDirect(data) {
    this.onlineDataEntryService.assignmentKey = data;
    this.router.navigate([`dataEntry/${this.schema}`],
      {
        queryParams: {assignmentField: data.assignmentField, assignmentValue: data.assignmentValue}
      });
  }

  transformStatus(onlineSubmissionsObject: OnlineSubmissions) {
    switch (onlineSubmissionsObject.status) {
      case 'NOT_STARTED' :
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.NOT_STARTED;
        return onlineSubmissionsObject;
        break;
      case 'IN_PROGRESS' :
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.IN_PROGRESS;
        return onlineSubmissionsObject;
        break;
      case 'PROCESSING' :
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.PROCESSING;
        return onlineSubmissionsObject;
        break;
      case 'SUBMITTED' :
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.SUBMITTED;
        return onlineSubmissionsObject;
        break;
      case 'UNKNOWN' :
      default:
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.UNKNOWN;
        this.messageService.add(new AlertMessage(`One of your filings has an unexpected status. Please contact your data call administrator.`, 'danger'));
        return onlineSubmissionsObject;
    }
  }

  getAllOnlineSubmissions() {
    this.loading = true;
    this.onlineDataEntryService.onlineSubmissions(this.schema)
      .subscribe(
        submissions => {
          // this will run on a successful request
          submissions.forEach((ele: OnlineSubmissions) => {
            let formattedSubmission = this.transformStatus(ele);
            formattedSubmission = this.transformAttachmentsDateAndSort(ele);
            this.data.push(formattedSubmission);
            this.loading = false;
          });
          this.getDatacallSettingValueForAllowAttachments();
        }, error => {
          // a 404 is expected for users with no assignments, so we don't need an alert message on a 404 as it is not a true 'error' per se
          if (error.status !== 404) {
            this.messageService.add(new AlertMessage(`Error retrieving your file submissions. Please contact your data call administrator. (${error.message})`, 'danger'));
          }
          this.loading = false;
        }
      );
  }

  getDatacallSettingValueForAllowAttachments(): void {
    this.loading = true;
    let datcallSettingName = DatacallSettingEnum.allowAttachments;
    this.datacallSettingService.getDatacallSettingName(this.user.currentSchema, datcallSettingName).subscribe(
      res => {
        this.onlineDataEntryService.isAllowAttachments = res;
        this.loading = false;
      }
    );
  }

  transformAttachmentsDateAndSort(data: OnlineSubmissions): OnlineSubmissions {
    data.attachments.forEach(attach => {
      attach.uploadDate = this.datePipe.transform(attach.uploadDate, 'MM/dd/yy, h:mm a');
    });
    data.attachments.sort((a, b) => a.originalFileName.localeCompare(b.originalFileName));
    return data;
  }

  downloadAttachment(attachment) {
    this.loading = true;
    this.onlineDataEntryService.getDownloadUrlfromS3(this.user, attachment.fileName).subscribe(response => {
      if(response) {
        this.loading = false;
        const presignedUrl = response.uploadURL;
        this.handleS3Download(presignedUrl);
      } else {
        this.loading = false;
        window.scroll(0,0);
      }
    });
  }

  handleS3Download(presignedUrl: string) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = presignedUrl;
    hiddenElement.target = '_blank';
    hiddenElement.click();
    hiddenElement.remove();
    window.scroll(0,0);
  }
}


