<div id="online-data-entry:sideNavigation" class="wrapper">

  <div id="onlineDataEntryActions" class="pb-2 mb-3 px-4 border-bottom">
    <h4>Actions</h4>
    <div id="actions">
      <div class="pb-2">
        <button
          id="save"
          type="button"
          [disabled]="!isDataChanged"
          class="btn btn-secondary btn-sm btn-block"
          (click)="onSave()">Save
        </button>
      </div>
      <div class="pb-2">
        <button
          id="saveAndValidate"
          type="button"
          class="btn btn-secondary btn-sm btn-block"
          [disabled]="isDisabled || isFormFormatError"
          (click)="onValidate()">Save and Validate
        </button>
      </div>
      <div class="pb-2">
        <button id="submit" type="button" class="btn btn-primary btn-sm btn-block"
                [disabled]="!isValidated" (click)="onSubmit()">Submit
        </button>
      </div>
      <div class="pb-2" *ngIf="onlineDataEntryService.isAllowAttachments">
        <button
          id="uploadAttachment"
          type="button"
          class="btn btn-secondary btn-sm btn-block"
          [disabled]="!onlineDataEntryService.isSummaryPage || isDisabled"
          (click)="uploadAttachment()">Upload Attachment
        </button>
      </div><!--
      <div class="pb-2">
        <button id="close" type="button" class="btn btn-danger btn-sm btn-block" (click)="onCancel()">Cancel
        </button>
      </div> -->
    </div>
  </div>

  <div class="pb-2 mb-3 px-4 border-bottom">
    <h4>
      Page Navigation
    </h4>


    <div id="online-data-entry:sections" class="bg-light">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="bg-light">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
                       (click)="changeStyleDynamically(node); goToSection(node)"
                       [ngClass]="{ 'background-highlight': onlineDataEntryService.activeNode.value === node.key }">
          <button mat-icon-button disabled></button>
          {{node.name}}
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                       (click)="changeStyleDynamically(node); goToSection(node)"
                       [ngClass]="{ 'background-highlight': onlineDataEntryService.activeNode.value === node.key }">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
                  [disabled]="!node.hasChild">
            <mat-icon class="mat-icon-rtl-mirror" *ngIf="node.hasChild">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <div *ngIf="hideQ(node)">
          <div *ngIf="flatNodeMap.get(node).questions.length > 0; else noQuestions" tabindex="0" class="cut-text"
               (keydown.enter)="goToSection(node)">
            <div *ngIf="!node.hasChild"></div>
            <div id="ln:{{node.name}}" #tooltip="matTooltip"
                 matTooltip="{{node.name}}" [matTooltipPosition]="tooltipPosition">
              {{node.name}}</div>
          </div>
        </div>
          <ng-template #noQuestions>
            <div tabindex="0" class="cut-text">
              <div *ngIf="!node.hasChild"></div>
              <div id="ln:{{node.name}}" #tooltip="matTooltip" matTooltip="{{node.name}}"
                   [matTooltipPosition]="tooltipPosition">
                {{node.name}}</div>
            </div>
          </ng-template>
        </mat-tree-node>
      </mat-tree>
      <div>
        <button id="summary" type="button" class="btn btn-primary btn-sm btn-block mt-3" (click)="onOpenSummary()">
          Summary
        </button>
      </div>
    </div>
  </div>

</div>
