import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-inputbox-field-renderer',
  templateUrl: './inputbox-field-renderer.component.html'
})
export class InputboxFieldRendererComponent implements ICellRendererAngularComp {
  params: any;



  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  checkValue(event: any, data) {
    this.params.context.componentParent.changeInputField(event.target.value, data.schemaProperty, this.params.colDef.field);
  }

}
