<ngx-loading [show]="loading"></ngx-loading>
<div class="wrapper">
  <div id="disclaimer">
    <div class="row">
      <div class="col-12 page-title">
        <h1>{{schema}} Filing Search</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>


    <div *ngIf="assignmentKeyFieldName" class="row mb-3">
      <div class="col-12">
        <ul class="list-inline">
          <li class="list-inline-item">
            <label hidden for="filingSearchInput">Filing Search Input</label>
            <input id="filingSearchInput" type="text"
                   class="form-control"
                   required [(ngModel)]="searchTerm"/>
          </li>
          <li class="list-inline-item">
            <button id="btn:search" type="button" class="btn btn-primary" (click)="searchForFilings()"><i
              class="fa fa-search"></i> Search
            </button>
          </li>
        </ul>
        <ul id="keyOrUserSelection" class="list-inline">
          <li class="list-inline-item">
            <label><input id="assignment-key-input" type="radio" (change)="selectFilingSearchMethod($event)"
                          name="options"
                          [checked]="searchByAssignmentKey"> {{assignmentKeyFieldName}}
            </label>
          </li>
          <li class="list-inline-item">
            <label><input id="user-id" type="radio" name="options"
                          (change)="selectFilingSearchMethod($event)"> User Id</label>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-12 page-title">
      <h2>Filing Matrix</h2>
    </div>

    <div *ngIf="data.length > 0" class="col-12">
      <table id="online-filings-table" class="table table-bordered shortened">
        <thead>
          <tr id="filing-matrix-headers">
            <th *ngFor="let header of tableHeaders" id={{header}} class="font-weight-bold text-capitalize">
              {{header}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <td>
              <a class="text-primary assignment-values" (click)="goToOnlineDataEntry(item.assignmentKey)">
                {{ item.assignmentKey.assignmentValue }}
              </a>
            </td>
            <td class="status-values">{{ item.status }}</td>
            <td *ngIf="onlineDataEntryService.isAllowAttachments">
              <ul>
                <li *ngFor="let attachment of item.attachments">
                  <a class="text-primary assignment-values" (click)="downloadAttachment(attachment)">
                    {{ attachment.originalFileName }} - {{attachment.uploadDate}}
                  </a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

