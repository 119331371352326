import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subject, Subscription} from 'rxjs';
import {Breadcrumb} from '../model/breadcrumb';
import {User} from '../model/user';
import {BreadcrumbService} from '../service/breadcrumb.service';
import {UserService} from '../service/user.service';
import {Property} from './model/property';
import {ValidationSchema} from './model/validation-schema';
import {FileValidationMaintenanceService} from './services/file-validation-maintenance.service';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from '../messages/service/message.service';
import {UnCamelCasePipe} from '../pipes/un-camel-case.pipe';
import {
  SchemaPropertyLinkRendererComponent
} from './cell-renderer/schema-property-link-renderer/schema-property-link-renderer.component';
import {
  RemoveSchemaPropertyRendererComponent
} from './cell-renderer/remove-schema-property-renderer/remove-schema-property-renderer.component';
import {AlertMessage} from '../messages/model/alert-message';
import {XlsIteration} from './model/xls-iteration';
import {XlsLocation} from './model/xls-location';
import {UserAssignmentService} from '../user-assignment/services/user-assignment.service';
import {OrderNumberRendererComponent} from './cell-renderer/order-number-renderer/order-number-renderer.component';
import {DatacallSettingService} from '../roleconfig/datacall-setting/services/datacall-setting.service';
import {DatacallSettingEnum} from '../roleconfig/datacall-setting/model/datacall-setting.enum';
import {
  CheckboxFieldRendererComponent
} from './cell-renderer/checkbox-field-renderer/checkbox-field-renderer.component';
import {AssignedToUser, PropertyNameEnum} from './model/property-name.enum';
import {
  InputboxFieldRendererComponent
} from './cell-renderer/inputbox-field-renderer/inputbox-field-renderer.component';
import {
  AssignmentKeyRendererComponent
} from './cell-renderer/assignment-key-renderer/assignment-key-renderer.component';
import {
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent} from 'ag-grid-community';
import {
  XlsInputboxFieldRendererComponent
} from './cell-renderer/xls-inputbox-field-renderer/xls-inputbox-field-renderer.component';
import {
  RadiobuttonFieldRendererComponent
} from './cell-renderer/radiobutton-field-renderer/radiobutton-field-renderer.component';
import {
  DropdownFieldRendererComponent
} from './cell-renderer/dropdown-field-renderer/dropdown-field-renderer.component';
import {DatafieldFileUploadComponent} from './datafield-file-upload/datafield-file-upload.component';
import {
  SubmissionKeyRendererComponent
} from './cell-renderer/submission-key-renderer/submission-key-renderer.component';
import { FormBuilderService } from '../form-builder/services/form-builder.service';
import { FilingBlank } from '../form-builder/model/filingblank';
import { FormSection } from '../form-builder/model/form-section';
import { ANSI } from '../../assets/reservedWords/ANSI';
import { InputsForQuestion } from '../form-builder/model/input';
import { Rules } from '../form-builder/model/rules';
import { RuleInputs } from '../form-builder/model/ruleInputs';

@Component({
  selector: 'app-schema-maintenance',
  templateUrl: './file-validation-maintenance.component.html'
})
export class FileValidationMaintenanceComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  title: string;
  user: User;
  validationSchema: ValidationSchema;
  newPropertyName: string;
  breadcrumb = new Breadcrumb('Datacall Field Definitions', '/schemaMaintenance');
  deleteKey: string;
  modalRef: BsModalRef;
  loading = false;
  scrollBarHorizontal = (window.innerWidth < 1200);
  newXlsIteration: XlsIteration;
  assignedToUser: AssignedToUser;
  dataSort: any[];
  replacedData: any[];
  showFileType: any = false;
  assignmentKeyChecked: boolean;
  listOfPropertyAssignmentKeys: any[] = [];
  originalProperty: any;
  isAssignedToUserObject: boolean;
  isNewProperty: boolean;
  property: Property;
  modalSubscriptions: Subscription[] = [];
  importDialogRef: BsModalRef;
  isOnlineDataEntryForm: boolean;


  // AgGrid
  columnDefs = [];
  data = [];
  public rowData: any[] | null = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridOptions: GridOptions = {
    singleClickEdit: true,
    suppressScrollOnNewData: true,
    deltaRowDataMode: true,
    getRowNodeId: function (data) {
      return data.id;
    }
  };
  infoMessage: string;
  showInfoMessage: boolean;
  updatedData: any[];
  originalData: any[];
  isGridReady = false;
  isOnlineDataEntry: boolean = false;
  usedSchemaProperties: any[]=[];
  usedSchemaPropertiesForDependency: any[]=[];
  isDescriptionempty: boolean = false;

  constructor(
    private schemaMaintenanceService: FileValidationMaintenanceService,
    private userService: UserService,
    private router: Router,
    public breadcrumbService: BreadcrumbService,
    private messageService: MessageService,
    private unCamelCase: UnCamelCasePipe,
    private userAssignmentService: UserAssignmentService,
    private datacallSettingService: DatacallSettingService,
    private modalService: BsModalService,
    private formBuilderService: FormBuilderService
  ) {
  }

  ngOnInit(): void {
    this.context = {componentParent: this};
    this.isOnlineDataEntryForm = this.datacallSettingService.getOnlineDataEntrySetting();
    this.frameworkComponents = {
      linkRenderer: SchemaPropertyLinkRendererComponent,
      removeRenderer: RemoveSchemaPropertyRendererComponent,
      orderRenderer: OrderNumberRendererComponent,
      checkboxRenderer: CheckboxFieldRendererComponent,
      inputboxRenderer: InputboxFieldRendererComponent,
      assignmentKeyRenderer: AssignmentKeyRendererComponent,
      xlsInputboxRenderer: XlsInputboxFieldRendererComponent,
      radiobuttonRenderer: RadiobuttonFieldRendererComponent,
      dropdownRenderer: DropdownFieldRendererComponent,
      submissionKeyRenderer: SubmissionKeyRendererComponent
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.user = this.userService.getStoredUser();
    this.breadcrumbService.clearBreadcrumbs();
    this.schemaMaintenanceService.setNewProperty(null);
    this.title = `${this.user.currentSchema.toUpperCase()} Datacall Field Definitions`;
    this.infoMessage = 'Users have been assigned, assignment keys are disabled';
    this.schemaMaintenanceService.setUsedSchemaProperties([]);
    this.getAssignmentKeys();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageService.clear();
  }

  getAssignmentKeys() {
    this.loading = true;
    this.userAssignmentService.getAssignmentKeys(this.user.currentSchema)
      .subscribe(
        (res: AssignedToUser) => {
          this.getDatacallSettingName();
          this.assignedToUser = res;
          this.showInfoMessage = this.assignedToUser.assignedToUser;
          this.schemaMaintenanceService.setIsAssignedToUser(this.assignedToUser);
        }
      );
  }

  getDatacallSettingName() {
    let datcallSettingName = DatacallSettingEnum.isOnlineDataEntry;
    this.datacallSettingService.getDatacallSettingName(this.user.currentSchema, datcallSettingName)
      .subscribe(
        res => {
          if (res === true) {
            this.isOnlineDataEntry = true;
            this.showFileType = true;
            this.getFilingBlankLayout();
          } else {
            this.showFileType = false;
            this.getCurrentValidationSchemaByType();
          }
          this.schemaMaintenanceService.showFileType = this.showFileType;
        }
      );
  }

  getFilingBlankLayout() {
    this.formBuilderService.getFilingBlankLayoutAndGenerateBlank(this.user.currentSchema)
    .subscribe(res => {
        this.getUniqueSchemaPropertyValueFromFilingBlankLayout(res.body);
        this.getCurrentValidationSchemaByType();
    }, err => {
      this.getCurrentValidationSchemaByType();
    });
  }

        private recursiveGetSchemaProperties(sections: FormSection[]) {
          sections.forEach(section => {
            section.questions.forEach(question => {
              this.processInputs(question.inputs);
              this.processRules(question.rules, question.inputs);
            });
      
            if (section.sections.length > 0) {
              this.recursiveGetSchemaProperties(section.sections);
            }
          });
      
          this.usedSchemaProperties = this.usedSchemaProperties.filter(str => !str.includes("Disabled"));
          this.usedSchemaPropertiesForDependency = this.usedSchemaPropertiesForDependency.filter(str => !str.includes("Disabled"));
        }  

      getUniqueSchemaPropertyValueFromFilingBlankLayout(res: FilingBlank) {
        let filingBlank: FilingBlank = res;
    
        if (filingBlank.questions.length > 0) {
          filingBlank.questions.forEach(question => {
            this.processInputs(question.inputs);
            this.processRules(question.rules, question.inputs);
          });
        }
    
        this.recursiveGetSchemaProperties(filingBlank.sections);
    
        this.schemaMaintenanceService.setUsedSchemaProperties(this.usedSchemaProperties);
        this.schemaMaintenanceService.setUsedSchemaPropertiesForDependency(this.usedSchemaPropertiesForDependency);
      }
    
      private processInputs(inputs: InputsForQuestion[]) {
        inputs.forEach(input => {
          this.usedSchemaProperties.push(input.schemaPropertyNameKey);
        });
      }
    
      private processRules(rules: Rules[], inputs: InputsForQuestion[]) {
        rules.forEach(rule => {
          rule.ruleInputs.forEach(ruleInput => {
            this.processRuleInput(ruleInput, inputs);
          });
        });
      }
    
      private processRuleInput(ruleInput: RuleInputs, inputs: InputsForQuestion[]) {
        const matchedInput = inputs.find(input => input.key === ruleInput.schemaPropertyNameKey);
        if (matchedInput) {
          this.usedSchemaPropertiesForDependency.push(matchedInput.schemaPropertyNameKey);
        }
      }
            

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.refreshCells();
    this.isGridReady = true;
  }

  getCurrentValidationSchemaByType(): void {
    this.loading = true;
    this.schemaMaintenanceService.getCurrentValidationSchemaByType(this.user.currentSchema)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        validationSchema => {
          this.validationSchema = validationSchema;

          const keys: Array<string> = this.getPropertiesKeys();
          let ccc: any[] = [];
          keys.forEach(x => {
            ccc.push(this.validationSchema.properties[x].isAssignmentsKey);
            if (this.isOnlineDataEntryForm && this.validationSchema.properties[x].isAssignmentsKey) {
              this.validationSchema.properties[x].isSubmissionKey = true;
            }
          });
          ccc.includes(true) ? this.schemaMaintenanceService.inc = true : this.schemaMaintenanceService.inc = false;

          this.setOrderNumberIfNotAvailable();
          this.schemaMaintenanceService.setSelectedValidationSchema(this.validationSchema);
          this.rowData = this.schemaMaintenanceService.getTableData(this.validationSchema);
          this.data = this.rowData;
          this.originalData = this.data;
          this.dataSort = this.schemaMaintenanceService.getTableData(this.validationSchema).slice();
          this.columnDefs = this.getColumnDef(this.data[0]);
          if (this.validationSchema.xlsIterations && this.validationSchema.xlsIterations[0]) {
            this.newXlsIteration = this.validationSchema.xlsIterations[0];
          } else {
            this.newXlsIteration = new XlsIteration();
          }
          this.loading = false;
        }
      );
  }

  setOrderNumberIfNotAvailable() {
    const keys: Array<string> = this.getPropertiesKeys();
    let dataForAddingOrderNumber: any[] = [];
    let count = 1;
    keys.forEach(key => {
      const data = {};
      data['orderNumber'] = this.validationSchema.properties[key].orderNumber;
      data['schemaProperty'] = key;
      dataForAddingOrderNumber.push(data);
    });
    dataForAddingOrderNumber.forEach(x => {
      if (x.orderNumber === undefined) {
        x.orderNumber = count;
        count++;
      } else {
        x.orderNumber = x.orderNumber;
      }
    });
    dataForAddingOrderNumber.sort((a, b) => parseFloat(a.orderNumber) - parseFloat(b.orderNumber));
    keys.forEach((key) => {
      const foundItem = dataForAddingOrderNumber.find(x => x.schemaProperty === key);
      if (foundItem) {
        this.validationSchema.properties[key].orderNumber = foundItem.orderNumber;
      }
    });
  }

  cancelUpdate() {
    this.getCurrentValidationSchemaByType();
    this.gridApi.setRowData(this.originalData);
    this.gridOptions.api.redrawRows();
  }

  getColumnDef(row: any): any[] {
    const columnsDef: any[] = [];
    if (!row) {
      return columnsDef;
    }
    const keys = Object.keys(row);
    keys.forEach(key => {
      const headerName = this.unCamelCase.transform(key);
      switch (headerName) {
        case 'Order Number':
          columnsDef.push({headerName: headerName, field: key, pinned: 'left', cellRenderer: 'orderRenderer'});
          break;
        case 'Schema Property':
          columnsDef.push({headerName: headerName, field: key, pinned: 'left', cellRenderer: 'linkRenderer'});
          break;
        case 'Description':
          columnsDef.push({
            headerName: headerName,
            field: key,
            pinned: 'left',
            cellRenderer: 'inputboxRenderer',
            editable: true
          });
          break;
        case 'Required Field':
        case 'Unique Record Identifier':
        case 'Pull From Worksheet':
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'checkboxRenderer'});
          break;
        case 'Submission Key':
          columnsDef.push({
            headerName: headerName, field: key, cellRenderer: 'submissionKeyRenderer', editable: false
          });
          break;
        case 'Min Value':
        case 'Max Value':
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'inputboxRenderer', editable: params => {
            return params.data.propertyType !== 'string';
          }});
          break;
        case 'Min Length':
        case 'Max Length':
        case'Pattern':
        case 'Any Of':
        case 'List Of Valid Values':
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'inputboxRenderer', editable: true});
          break;
        case 'Worksheet Location':
        case 'Row Location':
        case 'Column Exact Location':
          columnsDef.push({
            headerName: headerName, field: key, cellRenderer: 'xlsInputboxRenderer', editable: params => {
              return params.data.pullFromWorksheet !== true;
            }
          });
          break;
        case 'Worksheet Iteration Location':
        case 'Worksheet Exact Location':
        case 'Row Iteration Location':
        case 'Row Exact Location':
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'radiobuttonRenderer'});
          break;
        case 'Assignment Key':
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'assignmentKeyRenderer'});
          break;
        case 'Property Type':
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'dropdownRenderer'});
          break;
        case 'Remove':
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'removeRenderer', autoHeight: true});
          break;
        case 'Id':
          columnsDef.push({headerName: headerName, field: key, hide: true});
          break;
        default:
          columnsDef.push({headerName: headerName, field: key, resizable: false});
          break;
      }
    });
    return columnsDef;
  }

  schemaValidationAudit() {
    this.breadcrumbService.addBreadcrumb(this.breadcrumb);
    this.router.navigate(['/schemaAudit']);
  }

  addNewProperty() {
    this.messageService.clear();
    let reservedWords = ANSI;
    let orderNumbertoAdd: number;
    orderNumbertoAdd = this.schemaMaintenanceService.getLengthOfRows();
    let isPropertyKeyDuplicate = this.schemaMaintenanceService.isPropertyKeyDuplicate(this.newPropertyName, this.validationSchema);
    if (isPropertyKeyDuplicate) {
      this.messageService.add(new AlertMessage(`Schema property name '${this.newPropertyName}' already exists`, 'danger'));
    } else if (/\s/.test(this.newPropertyName)) {
      this.messageService.add(new AlertMessage(`Spaces not allowed in property name`, 'danger'));
    } else if (reservedWords.includes(this.newPropertyName)) {
      this.messageService.add(new AlertMessage(`Schema property name '${this.newPropertyName}' is a reserved word which cannot be used.`, 'danger'));
    } else {
      const propertyToAdd: Property = {
        name: this.newPropertyName,
        type: 'string',
        required: false,
        isSubmissionKey: false,
        isDocumentKey: false,
        isAssignmentsKey: false,
        description: '',
        orderNumber: orderNumbertoAdd + 1,
        xlsLocation: this.validationSchema.fileType === 'XLSX' ? new XlsLocation() : null
      };
      this.schemaMaintenanceService.setNewProperty(propertyToAdd);
      this.breadcrumbService.addBreadcrumb(this.breadcrumb);
      this.router.navigate(['/schemaPropertyMaintenance']);
    }
  }

  getPropertiesKeys(): string[] {
    return _.keys(this.validationSchema.properties);
  }

  objSize(property: Property): number {
    const exclusions = ['name', 'required', 'isSubmissionKey', 'isDocumentKey', 'isAssignmentsKey', 'description', 'orderNumber'];
    let count = 0;
    _.keys(property).forEach(key => {
      if (exclusions.indexOf(key) === -1 || this.isPropertySetToTrue(property, key)) {
        count += 1;
      }
    });
    return count;
  }

  confirmRemove(key: string): void {
    delete this.validationSchema.properties[key];
    this.setOrderNumberAfterDelete();
    this.saveValidationSchema();
  }

  changeCheckboxField(value, key, columnName) {
    if (columnName === 'pullFromWorksheet') {
      let propertyName = PropertyNameEnum[columnName];
      this.validationSchema.properties[key][propertyName].useWorksheetName = value;
    } else {
      let propertyName = PropertyNameEnum[columnName];
      this.validationSchema.properties[key][propertyName] = value;
    }
    this.data = this.schemaMaintenanceService.getTableData(this.validationSchema);
    this.gridApi.setRowData(this.data);
    this.gridOptions.api.redrawRows();
  }

  changeInputField(value, key, columnName) {
    let propertyName = PropertyNameEnum[columnName];
    this.validationSchema.properties[key][propertyName] = value;
    this.removeEmptyFields(this.validationSchema.properties[key]);
    Object.keys(this.data).forEach(x => {
      if (this.data[x]['id'] === key) {
        this.data[x][columnName] = value;
      }
    });
  }

  changeXlsInputField(value, key, columnName) {
    let propertyName = PropertyNameEnum[columnName];
    switch (columnName) {
      case 'worksheetLocation': {
        this.validationSchema.properties[key][propertyName].worksheet.numberedLocation = value;
        break;
      }
      case 'rowLocation': {
        this.validationSchema.properties[key][propertyName].row.numberedLocation = value;
        break;
      }
      default: {
        this.validationSchema.properties[key][propertyName].column.numberedLocation = value;
        break;
      }
    }
    Object.keys(this.data).forEach(x => {
      if (this.data[x]['id'] === key) {
        this.data[x][columnName] = value;
      }
    });
  }

  changeAssignmentKeyField(value, key, columnName) {
    const keys: Array<string> = this.getPropertiesKeys();
    let propertyName = PropertyNameEnum[columnName];
    this.validationSchema.properties[key][propertyName] = value;

    let ccc: any[] = [];
    keys.forEach(x => {
      ccc.push(this.validationSchema.properties[x][propertyName]);
    });
    ccc.includes(true) ? this.schemaMaintenanceService.inc = true : this.schemaMaintenanceService.inc = false;

    if (this.isOnlineDataEntryForm) {
      this.changeSubmissionKeyField(value, key, 'submissionKey');
    }

    this.data = this.schemaMaintenanceService.getTableData(this.validationSchema);
    this.gridApi.setRowData(this.data);
    this.gridOptions.api.redrawRows();
  }

  changeSubmissionKeyField(value, key, columnName) {
    let propertyName = PropertyNameEnum[columnName];
    this.validationSchema.properties[key][propertyName] = value;
  }

  changeRadioButtonField(value, key, columnName) {
    let propertyName = PropertyNameEnum[columnName];
    let rowNode = this.gridApi.getRowNode(`${key}`);
    switch (columnName) {
      case 'worksheetIterationLocation': {
        this.validationSchema.properties[key][propertyName].worksheet.useIterationValue = true;
        break;
      }
      case 'worksheetExactLocation': {
        this.validationSchema.properties[key][propertyName].worksheet.useIterationValue = false;
        break;
      }
      case 'rowIterationLocation': {
        this.validationSchema.properties[key][propertyName].row.useIterationValue = true;
        break;
      }
      case 'rowExactLocation': {
        this.validationSchema.properties[key][propertyName].row.useIterationValue = false;
        break;
      }
      default: {
        break;
      }
    }
    this.data = this.schemaMaintenanceService.getTableData(this.validationSchema);
    this.gridApi.setRowData(this.data);
    this.gridOptions.api.redrawRows();
  }

  changeDropdownField(value, key, columnName) {
    let propertyName = PropertyNameEnum[columnName];
    this.validationSchema.properties[key][propertyName] = value;
    this.data = this.schemaMaintenanceService.getTableData(this.validationSchema);
    this.gridApi.setRowData(this.data);
    this.gridOptions.api.redrawRows();
  }

  removeEmptyFields(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === '') {
        delete obj[key];
      }
    });
  }

  isPropertySetToTrue(property: Property, key: string): boolean {
    return (property[key] === 'true' || property[key] === true);
  }

  saveValidationSchema(): void {
    this.loading = true;
    this.setRowDataToValidationSchema(this.data);
    this.schemaMaintenanceService.saveValidationSchema(this.validationSchema)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          this.checkForOde();
          this.data = this.schemaMaintenanceService.getTableData(this.validationSchema);
          if (this.isGridReady && this.gridOptions.api !== null) {
            this.gridApi.setRowData(this.data);
            this.gridOptions.api.redrawRows();
            this.messageService.clear();
          }
          document.documentElement.scrollTop = 0;
          this.messageService.add(new AlertMessage('Datacall Field Definitions have been updated.', 'success'));
        }
      );
  }

  checkForOde() {
    if(this.isOnlineDataEntry) {
      this.updateFormBuilder();
    } else {
      this.getCurrentValidationSchemaByType();
    }
  }

  updateFormBuilder() {
    return this.formBuilderService.getFilingBlankLayoutAndGenerateBlank(this.user.currentSchema)
    .subscribe(res => {
      if(res.status === 200) {
        this.updateFilingBlankLayout(res.body);
      }
    }, err => {
      if (err.status === 404) {
      this.getCurrentValidationSchemaByType();
      }
    });
  }

  updateFilingBlankLayout(res: FilingBlank) {
    return this.formBuilderService.postFilingBlankLayout(this.user.currentSchema, res)
    .subscribe(() => {
      this.getCurrentValidationSchemaByType();
    });
  }

  setRowDataToValidationSchema(data) {
    Object.keys(data).forEach(x => {
      Object.keys(this.validationSchema.properties).forEach(z => {
        if (data[x]['id'] === z) {
          let validationSchema = this.validationSchema.properties[z];
          validationSchema.description = this.checkForEmptyOrSpaces(data[x].description);
          validationSchema.max = this.checkForEmptyOrSpaces(data[x].maxValue);
          validationSchema.min = this.checkForEmptyOrSpaces(data[x].minValue);
          validationSchema.minLength = this.checkForEmptyOrSpaces(data[x].minLength);
          validationSchema.maxLength = this.checkForEmptyOrSpaces(data[x].maxLength);
          validationSchema.pattern = this.checkForEmptyOrSpaces(data[x].pattern);
          validationSchema.enum = this.checkForEmptyOrSpaces(data[x].listOfValidValues);
          validationSchema.anyOf = this.checkForEmptyOrSpaces(data[x].anyOf);
          if (this.validationSchema.fileType === 'XLSX') {
            validationSchema.xlsLocation.worksheet.numberedLocation = this.checkForEmptyOrSpaces(data[x].worksheetLocation);
            validationSchema.xlsLocation.row.numberedLocation = this.checkForEmptyOrSpaces(data[x].rowLocation);
            validationSchema.xlsLocation.column.numberedLocation = this.checkForEmptyOrSpaces(data[x].columnExactLocation);
          }
          if(validationSchema.type === 'string') {
            delete validationSchema.max;
            delete validationSchema.min;
          }
        }
      });
    });
  }

  checkForEmptyOrSpaces(str) {
    if (typeof(str) === 'string' && str.trim() !== '' || Array.isArray(str) && str.length > 0) {
      return str;
    } else {
      return undefined;
    }
  }

  saveCompleteValidationSchema() {
    if(this.validationSchema.fileType === 'XLSX'){
      this.saveXlsIteration();
    } else {
      this.saveValidationSchema();
    }
  }

  saveXlsIteration() {
    if (!this.validationSchema.xlsIterations) {
      this.validationSchema.xlsIterations = [this.newXlsIteration];
    } else {
      this.validationSchema.xlsIterations[0] = this.newXlsIteration;
    }
    this.saveValidationSchema();
  }

  goToPropertyMaintenance(key: string): void {
    this.breadcrumbService.addBreadcrumb(this.breadcrumb);
    const property = this.validationSchema.properties[key];
    this.schemaMaintenanceService.setSelectedPropertyName(property.name);
    this.router.navigate(['/schemaPropertyMaintenance']);
  }

  isAddDisabled(newPropertyName: string): boolean {
    return !newPropertyName || newPropertyName.length === 1 || newPropertyName.trim().length === 1;
  }

  changeOrderNumber(changedValue, defaultValue) {
    let new_index = changedValue - 1;
    let old_index = defaultValue - 1;
    let arr = this.dataSort;
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    this.replacedData = arr.slice();
    let count = 1;
    this.replacedData.forEach(x => {
      x.orderNumber.orderNumber = count;
      count++;
    });
    this.saveReorderedFields();
    return arr;
  }

  setOrderNumberAfterDelete() {
    const keys: Array<string> = this.getPropertiesKeys();
    let dataForValidation: any[] = [];
    let count = 1;
    keys.forEach(key => {
      const data = {};
      data['orderNumber'] = this.validationSchema.properties[key].orderNumber;
      data['schemaProperty'] = key;
      dataForValidation.push(data);
    });
    dataForValidation.sort((a, b) => parseFloat(a.orderNumber) - parseFloat(b.orderNumber));
    dataForValidation.forEach(x => {
      x.orderNumber = count;
      count++;
    });
    keys.forEach((key) => {
      const foundItem = dataForValidation.find(x => x.schemaProperty === key);
      if (foundItem) {
        this.validationSchema.properties[key].orderNumber = foundItem.orderNumber;
      }
    });
  }

  saveReorderedFields() {
    const keys: Array<string> = this.getPropertiesKeys();
    keys.forEach((key) => {
      const foundItem = this.replacedData.find(x => x.schemaProperty === key);
      if (foundItem) {
        this.validationSchema.properties[key].orderNumber = foundItem.orderNumber.orderNumber;
      }
    });
    this.saveValidationSchema();
  }

  onChangeFileType(event) {
    this.validationSchema.fileType = event.target.selectedOptions[0].value;
    if (this.validationSchema.fileType === 'XLSX') {
      if (!this.validationSchema.xlsIterations || this.validationSchema.xlsIterations === null) {
        this.validationSchema.xlsIterations = [new XlsIteration()];
      }
      this.getPropertiesKeys().forEach(key => {
        if (!this.validationSchema.properties[key].xlsLocation || this.validationSchema.properties[key].xlsLocation === null) {
          this.validationSchema.properties[key].xlsLocation = new XlsLocation();
        }
      });
      this.newXlsIteration = this.validationSchema.xlsIterations[0];
    }
    this.saveValidationSchema();
  }

  isSaveDisabled(): boolean {
    this.checkDescriptionField();
    if (this.isDescriptionempty) {
      return true;
    }

    if (this.validationSchema.fileType === 'XLSX') {
      if (!this.newXlsIteration) {
        return true;
      }
        if (this.notDigitOrPlus('' + this.newXlsIteration.rowRange.begin) || this.notDigitOrPlus(this.newXlsIteration.rowRange.end)) {
          return true;
        }
        if (this.notDigitOrPlus('' + this.newXlsIteration.worksheetRange.begin) || this.notDigitOrPlus(this.newXlsIteration.worksheetRange.end)) {
          return true;
        }
    }
    return false;
  }

  checkDescriptionField(): void {
    this.isDescriptionempty = false;
    Object.keys(this.data).forEach(x => {
      const description = this.checkForEmptyOrSpaces(this.data[x].description);
      if (!description) {
        this.isDescriptionempty = true;
      }
    });
  }

  private notDigitOrPlus(field: string) {
    const re = /^([1-9+][0-9]*|\+)$/;
    return !re.exec(field);
  }

  UploadFormattedFile() {
    const initialState = {user: this.user};
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      })
    );
    this.importDialogRef = this.modalService.show(DatafieldFileUploadComponent, {
      initialState: {
        ...initialState,
        formatedFile: true,
        fileExt: ['XLSX']
      }, class: 'modal-lg'
    });
    this.importDialogRef.content.events.subscribe(() => {
      this.getCurrentValidationSchemaByType();
      this.messageService.add(new AlertMessage('File Uploaded Successfully.', 'success'));
    });
  }

  uploadDataField() {
    const initialState = {user: this.user};
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      })
    );
    this.importDialogRef = this.modalService.show(DatafieldFileUploadComponent, {
      initialState: initialState, class: 'modal-lg'
    });
    this.importDialogRef.content.events.subscribe(() => {
      this.getCurrentValidationSchemaByType();
      this.messageService.add(new AlertMessage('File Uploaded Successfully.', 'success'));
    });
  }

  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }

  isUploadDisabled(): boolean {
    if (this.showInfoMessage) {
      return true;
    } else {
      return false;
    }
  }

}
