import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessagesModule} from '../../messages/messages.module';
import {LoadingModule} from 'ngx-loading';
import {FilingSearchComponent} from './filing-search.component';
import {FilingSearchRoutingModule} from './filing-search-routing.module';
import {PipesModule} from '../../pipes/pipes.module';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    FilingSearchRoutingModule,
    CommonModule,
    MessagesModule,
    LoadingModule,
    PipesModule,
    FormsModule
  ],
  declarations: [FilingSearchComponent],
  entryComponents: [FilingSearchComponent],
})

export class FilingSearchModule {
}
