import {Component, OnInit} from '@angular/core';
import {MessageService} from '../../messages/service/message.service';
import {User} from '../../model/user';
import {UserService} from '../../service/user.service';
import {FileValidationMaintenanceService} from '../../file-validation-maintenance/services/file-validation-maintenance.service';
import {AlertMessage} from '../../messages/model/alert-message';
import {UserAssignmentService} from '../../user-assignment/services/user-assignment.service';
import {OnlineDataEntryService} from '../services/online-data-entry.service';
import {OnlineSubmissions} from '../model/online-submissions';
import {IFilingSearchObject} from '../model/filing-search-object';
import * as _ from 'lodash';
import {UserAssignments} from '../../user-assignment/model/user-assignment';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { DatacallSettingEnum } from '../../roleconfig/datacall-setting/model/datacall-setting.enum';
import { DatacallSettingService } from '../../roleconfig/datacall-setting/services/datacall-setting.service';

/**
 * This component appears for administrators of an online data entry data call.
 */
@Component({
  selector: 'app-filing-search',
  templateUrl: './filing-search.component.html',
  styleUrls: ['./filing-search.component.css']
})
export class FilingSearchComponent implements OnInit {
  loading = false;
  schema: string;
  user: User;
  assignmentKeyFieldName: string;
  userAssignments: UserAssignments[];
  validationSchema: any;
  searchByAssignmentKey = true;
  searchTerm: string;
  data: OnlineSubmissions[] = [];
  tableHeaders: string[] = [];
  activeSearch: IFilingSearchObject = {
    searchByAssignmentKey: false,
    searchTerm: ''
  };

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private fileValidationMaintenaceService: FileValidationMaintenanceService,
    private userAssignmentService: UserAssignmentService,
    private onlineDataEntryService: OnlineDataEntryService,
    private router: Router,
    private datePipe: DatePipe,
    private datacallSettingService: DatacallSettingService) {
  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.schema = this.user.currentSchema.toLocaleUpperCase();

    this.userAssignmentService.getAssigmentsForDatacall(this.schema).subscribe(assignments => {
      this.userAssignments = assignments;
    });


    // this method gets current data call fields and finds the ones where assignment key is true
    // if there is only one assignment key, it is set to the assignmentKeyFieldName property
    this.getAssignmentKeyNameFromDatcallFieldDefinitions();
  }

  selectFilingSearchMethod(event) {
    this.searchByAssignmentKey = event.target.id === 'assignment-key-input';
  }

  searchForFilings() {
    this.messageService.clear();

    if (this.isNewSearch() && this.notAllSpacesInSearchTerm(this.searchTerm)) {
        this.activeSearch.searchByAssignmentKey = this.searchByAssignmentKey;
        this.activeSearch.searchTerm = this.searchTerm;
        this.clearTableData();
        
        if (this.activeSearch.searchByAssignmentKey === true) {
            this.searchByAssignmentKeys();
        } else {
            this.searchByUserId();
        }
    }
}

  searchByAssignmentKeys() {
    this.loading = true;

    if (this.userAssignmentService.assignmentValueHasAssignedUsers(this.userAssignments, this.activeSearch.searchTerm)) {
      this.onlineDataEntryService.getOnlineFilingsByAssignmentKey(this.schema, this.assignmentKeyFieldName, this.searchTerm)
        .subscribe(onlineSubmissions => {
          this.handleOnlineSubmissions(onlineSubmissions);
        }, error => {
          this.handleError(error);
        });
    } else {
      this.messageService.add(new AlertMessage(`This assignment value does not have any assignments.`, 'danger'));
      this.loading = false;
    }
  }

  searchByUserId() {
    this.loading = true;

    this.onlineDataEntryService.getOnlineFilingsByUserId(this.schema, this.searchTerm).subscribe(submissions => {
      if (submissions.length === 0) {
        this.messageService.add(new AlertMessage(`This user is not assigned any filings.`, 'danger'));
      } else {
        this.handleOnlineSubmissions(submissions);
      }
      this.loading = false;
    }, error => {
      this.handleError(error);
    });
  }

  handleOnlineSubmissions(submissions: any[]) {
    submissions.forEach(submission => {
      let transformedObj = this.onlineDataEntryService.transformStatus(submission);
      transformedObj = this.transformFilingSearch(submission);
      this.data.push(transformedObj);
    });

    const assignmentField = this.data[0].assignmentKey.assignmentField;
    this.tableHeaders.push(assignmentField);
    this.tableHeaders.push('Filing Status');
    if(this.onlineDataEntryService.isAllowAttachments){this.tableHeaders.push('Attachments');};
    this.loading = false;
  }

  handleError(error: any) {
    if (error.status === 404) {
      this.messageService.add(new AlertMessage(`This does not appear to be a valid username.`, 'danger'));
    } else {
      this.messageService.add(new AlertMessage(`Something went wrong retrieving filings.`, 'danger'));
    }
    this.loading = false;
  }

  goToOnlineDataEntry(assignmentKey) {
    this.router.navigate([`dataEntry/${this.schema}`]);
    this.onlineDataEntryService.assignmentKey = assignmentKey;
  }

  clearTableData() {
    this.data = [];
    this.tableHeaders = [];
  }

  notAllSpacesInSearchTerm(searchTerm: string) {
    const searchTermNoSpaces = searchTerm.replace(/\s+/g, '');
    if (searchTermNoSpaces === undefined || searchTermNoSpaces === '' || searchTermNoSpaces.length === 0) {
      this.searchTerm = '';
      this.messageService.add(new AlertMessage('Please enter a valid search term.', 'warning'));
      return false;
    }
    return true;
  }

  isNewSearch() {
    const newSearch: IFilingSearchObject = {
      searchByAssignmentKey: true,
      searchTerm: ''
    };
    newSearch.searchByAssignmentKey = this.searchByAssignmentKey;
    newSearch.searchTerm = this.searchTerm;

    return JSON.stringify(newSearch) !== JSON.stringify(this.activeSearch);
  }

  getAssignmentKeyNameFromDatcallFieldDefinitions() {
    this.loading = true;
    this.fileValidationMaintenaceService.getCurrentValidationSchemaByType(this.schema)
      .subscribe(
        validationSchema => {
          this.validationSchema = validationSchema;
          let key: Array<string> = _.keys(this.validationSchema.properties);
          let properties: any[] = [];
          let assignmentKeys: string[] = [];
          key.forEach(x => {
            properties.push(this.validationSchema.properties[x]);
          });
          properties.forEach(prop => {
            if (prop.isAssignmentsKey) {
              assignmentKeys.push(prop.name);
            }
          });
          if (assignmentKeys.length === 0) {
            this.messageService.add(new AlertMessage(`This data call does not have an assignment key.`, 'danger'));
          } else if (assignmentKeys.length > 1) {
            this.messageService.add(new AlertMessage(`This data call has more than one assignment key.`, 'danger'));
          } else {
            this.assignmentKeyFieldName = assignmentKeys[0];
          }
          this.getDatacallSettingValueForAllowAttachments();
          this.loading = false;
        }
      );
  }

  getDatacallSettingValueForAllowAttachments(): void {
    this.loading = true;
    let datcallSettingName = DatacallSettingEnum.allowAttachments;
    this.datacallSettingService.getDatacallSettingName(this.user.currentSchema, datcallSettingName).subscribe(
      res => {
        this.onlineDataEntryService.isAllowAttachments = res;
        this.loading = false;
      }
    );
  }

  transformFilingSearch(submission: OnlineSubmissions): OnlineSubmissions {
    submission.attachments.forEach(attach => {
      attach.uploadDate = this.datePipe.transform(attach.uploadDate, 'MM/dd/yy, h:mm a');
    });
    submission.attachments.sort((a, b) => a.originalFileName.localeCompare(b.originalFileName));
    return submission;
  }

  downloadAttachment(attachment) {
    this.loading = true;
    this.onlineDataEntryService.getDownloadUrlfromS3(this.user, attachment.fileName).subscribe(response => {
      if(response) {
        this.loading = false;
        const presignedUrl = response.uploadURL;
        this.handleS3Download(presignedUrl);
      } else {
        this.loading = false;
        window.scroll(0,0);
      }
    });
  }

  handleS3Download(presignedUrl: string) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = presignedUrl;
    hiddenElement.target = '_blank';
    hiddenElement.click();
    hiddenElement.remove();
    window.scroll(0,0);
  }

}
