<ngx-loading [show]="loading"></ngx-loading>

<div class="wrapper">
  <div id="schema-property-view">
    <div class="row page-title">
      <div class="col-12">
        <h1>{{title}}</h1>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
              <a [routerLink]="crumb.link" (click)="breadcrumbService.removeBreadcrumb(crumb)">{{crumb.label}}</a>
            </li>
            <li class="breadcrumb-item active">
              {{breadcrumb.label}}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div *ngIf="!isNameFieldDisabled else formBuilderInfo" id="infoMessage" class="row mb-3">
      <div class="col-12">
        <alert type="info" [dismissible]="false">
          <span>{{infoMessage}}</span>
        </alert>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <form name="schemaPropertyForm" (ngSubmit)="save(confirmSaveModal)" #schemaPropertyForm="ngForm">

          <div class="mb-3">
            <button id="btn:add_new_property" type="button" class="btn btn-primary" (click)="addNewProperty()"
                    [disabled]="!isPropertyUnchanged() || !schemaPropertyForm.form.valid">
                    <i class="fa fa-plus"> Add New Property</i><span class="sr-only">Add</span>
            </button>  &nbsp;
            <button *ngIf="isNextDisplayed" id="btn:next" type="button" class="btn btn-primary" (click)="next()"
                    [disabled]="!isPropertyUnchanged() || !schemaPropertyForm.form.valid">
                    Next <span class="sr-only">Next</span>
            </button>  &nbsp;
            <button *ngIf="isPreviousDisplayed" id="btn:previous" type="button" class="btn btn-primary" (click)="previous()"
                    [disabled]="!isPropertyUnchanged() || !schemaPropertyForm.form.valid">
                    Previous <span class="sr-only">Previous</span>
            </button>  &nbsp;
          </div>

          <div class="mb-3">
            <div *ngIf="nameAlreadyExists" style="color: #dc3545!important;padding-left: 98px">Schema property name '{{property.name}}'
              already exists
            </div>
            <div *ngIf="isReservedWords" style="color: #dc3545!important;padding-left: 98px">Schema property name '{{property.name}}' is a reserved word which cannot be used.
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend text-capitalize">
                <span class="input-group-text font-weight-bold">Name: <span *ngIf="!isNameFieldDisabled" class="text-danger">*</span></span>
              </div>
              <input id="input:name:default" name="name" type="text" class="form-control" [disabled]="isNameFieldDisabled"
                     [(ngModel)]="property.name" (keyup)="changeName(property)" minlength="2" required appNoSpace>
            </div>
          </div>

          <div class="mb-3">
            <h3 id="propertyTypeLabel">Property Type: </h3>
            <div dropdown class="btn-group">
              <button dropdownToggle id="btn:propertyType" type="button" class="btn btn-secondary dropdown-toggle">
                {{property.type}} <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li *ngFor="let type of propertyTypes" role="menuitem">
                  <button type="button" class="dropdown-item" (click)="setType(type)">{{type}}</button>
                </li>
              </ul>
            </div>
          </div>

          <div class="checkbox mb-3">
            <label>
              <input id="checkbox:required" name="checkbox:required" type="checkbox" [(ngModel)]="property.required"
                     aria-label="required"> Required field
            </label>
            <br>
            <label>
              <input id="checkbox:recordKey" name="checkbox:recordKey" type="checkbox"
                     [(ngModel)]="property.isDocumentKey" aria-label="recordKey"> Unique Record Identifier
            </label>
            <br>
            <label>
              <input id="checkbox:submissionKey" name="checkbox:submissionKey" type="checkbox"
                     [(ngModel)]="property.isSubmissionKey"
                     [disabled]="isOnlineDataEntryForm" aria-label="submissionKey">
              Submission key: This field in conjunction with other fields marked as submission keys will uniquely
              identify a file submission
            </label>
            <br>
            <label>
              <input id="checkbox:isAssignmentsKey" name="checkbox:isAssignmentsKey" type="checkbox"
                     (change)="registerAssignmentKeyChange($event)"
                     [(ngModel)]="property.isAssignmentsKey" aria-label="isAssignmentsKey"
                     [disabled]="disableAssignmentsKey()"> Assignments key
              <span id="info:isAssignmentKeyAssigned" *ngIf="isAssignedToUserObject" style="font-style: italic;">&emsp;Users have been assigned</span>
            </label>
          </div>
          <ng-container *ngFor="let key of getPropertyKeys()">
            <ng-container *ngIf="shouldDisplay(key)">
              <div class="input-group mb-3">
                <div class="input-group-prepend text-capitalize">
                  <span class="input-group-text font-weight-bold">{{getPropertyDisplayValue(key) | unCamelCase}}<span
                    class="text-danger">*</span></span>
                </div>
                <input id="input:{{key}}:default" name="input:{{key}}:default" type="text" class="form-control"
                       [(ngModel)]="property[key]" required notOnlyWhitespace>
                <div class="input-group-append">
                  <button *ngIf="key !== 'description'" id="btn:delete:{{key}}" class="btn btn-danger"
                          (click)="delete(key, confirmDeleteProperty)" type="button"
                          tooltip="Delete validation from property">
                    <i class="fa fa-times"></i><span class="sr-only">Delete</span>
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="mb-3">
            <div dropdown class="btn-group">
              <button dropdownToggle id="btn:add" type="button" class="btn btn-secondary dropdown-toggle" [disabled]="isAddValidationDisabled()">
                <i class="fa fa-plus"></i> Add Validation
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li *ngFor="let availableValidation of availableValidations" role="menuitem">
                  <button type="button" class="dropdown-item" (click)="addValidationToProperty(availableValidation)">
                    {{getPropertyDisplayValue(availableValidation)}}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div class="mb-3">
            <span class="text-danger">*</span> = Required if present
          </div>

          <div *ngIf="validationSchema.fileType == 'XLSX' && showFileType === false">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title">XLSX File Location</h3>
                <div class="checkbox mb-3">
                  <label>
                    <input id="checkbox:worksheetname" name="checkbox:worksheetname" type="checkbox"
                           [(ngModel)]="property.xlsLocation.useWorksheetName"
                           aria-label="required"> Pull from worksheet name
                  </label>
                  <br>
                </div>
                <ng-container *ngIf="property.xlsLocation.useWorksheetName == false" class="checkbox mb-3">
                  <div class="radio mb-3 form-inline">
                    <label>
                      <div class="col">Worksheet:</div>
                      <div class="form-inline">
                        <input id="radio:worksheetuseiteration" name="radio:worksheettype" type="radio"
                               [(ngModel)]="property.xlsLocation.worksheet.useIterationValue" [value]="true"> Use
                        Iteration location
                        <div class="pl-2">
                          <input id="radio:worksheetuseexact" name="radio:worksheettype" type="radio"
                                 [(ngModel)]="property.xlsLocation.worksheet.useIterationValue" [value]="false"> Use
                          Exact
                          location
                        </div>
                        <div *ngIf="property.xlsLocation.worksheet.useIterationValue == false" class="pl-2">
                          <input id="text:worksheetuseexact" type="text" aria-label="required"
                                 name="input:workseetexactlocation"
                                 [(ngModel)]="property.xlsLocation.worksheet.numberedLocation" pattern="[1-9]\d*$">
                        </div>
                      </div>
                    </label>

                  </div>
                  <div class="radio mb-3 form-inline">
                    <label>
                      <div class="col">Row:</div>
                      <div class="form-inline">
                        <input id="radio:rowuseiteration" name="radio:rowtype" type="radio"
                               [(ngModel)]="property.xlsLocation.row.useIterationValue" [value]="true"> Use Iteration
                        location
                        <div class="pl-2">
                          <input id="radio:rowuseexact" name="radio:rowtype" type="radio"
                                 [(ngModel)]="property.xlsLocation.row.useIterationValue" [value]="false"> Use Exact
                          location
                        </div>
                        <div *ngIf="property.xlsLocation.row.useIterationValue == false" class="pl-2">
                          <input id="text:rowuseexact" type="text" aria-label="required" name="input:rowexactlocation"
                                 [(ngModel)]="property.xlsLocation.row.numberedLocation" pattern="[1-9]\d*$">
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="radio mb-3 form-inline">
                    <label>
                      <div class="col">Column Exact Location:</div>
                      <div class="form-inline">
                        <div class="pl-2">
                          <input id="text:columnuseexact" type="text" aria-label="required"
                                 name="input:columnexactlocation"
                                 [(ngModel)]="property.xlsLocation.column.numberedLocation" pattern="[1-9]\d*$"
                                 required>
                        </div>
                      </div>
                    </label>
                  </div>
                  <br>
                </ng-container>
              </div>
            </div>
          </div>
          <br>

          <div class="mb-3">
            <button type="button" class="btn btn-link btn-sm" (click)="cancel()">Cancel</button>
            <button id="btn:save" type="submit" class="btn btn-primary"
                    [disabled]="!schemaPropertyForm.form.valid || isPropertyUnchanged() || nameAlreadyExists || isReservedWords">
              <i class="fa fa-floppy-o"></i> Save
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>


<ng-template #confirmSaveModal>
  <div class="modal-header">
    <h3 class="modal-title">Alter Property</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Altering this property after filings have been received can result in processing errors. Are you sure you wish to
    proceed?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="cancelSave()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="confirmSave()">Proceed</button>
  </div>
</ng-template>

<ng-template #confirmDeleteProperty>
  <div class="modal-header">
    <h3 class="modal-title">Alter Property</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Altering this property after filings have been received can result in processing errors. Are you sure you wish to
    proceed?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="deleteValidationFromProperty()">Proceed</button>
  </div>
</ng-template>

<ng-template #formBuilderInfo>
  <div id="formBuilderInfoMessage" class="row mb-3">
    <div class="col-12">
      <alert type="info" [dismissible]="false">
        <span>{{formBuilderInfoMessage}}</span>
      </alert>
    </div>
  </div>
</ng-template>
